import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppInitService } from '@core/services/app-init.service';
import { AuthService } from '@core/services/auth.service';
import { ConsultantService } from '@core/services/consultant.service';
import { UserService } from '@core/services/user.service';
import * as addressActions from '@core/store/address/address.actions';
import * as consultantActions from '@core/store/consultant/consultant.actions';
import * as userActions from '@core/store/user/user.actions';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getErrorMessage } from '@shared/utils/get-error-message';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AppState } from '..';
import { selectCurrentConsultant } from '../consultant';

@Injectable()
export class UserEffects {
  email: string;
  password: string;

  register$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.registerUser),
      concatLatestFrom(() => this.store$.select(selectCurrentConsultant)),
      exhaustMap(([{ payload, token }, currentConsultant]) =>
        this.userService
          .registerUser({
            firstName: payload.name.firstName,
            lastName: payload.name.lastName,
            email: payload.email.email,
            password: payload.password.password,
            newsletters: payload.subscriptions.newsletters,
            phoneNumber: payload.phoneNumber,
            activeConsultantProwessId: currentConsultant?.beeNumber,
            consultantName: currentConsultant?.fullName,
            consultantPhoneNumber: currentConsultant?.phoneNumber,
            consultantEmail: currentConsultant?.email,
            shareWithConsultant: payload.shareWithConsultant,
            token,
          })
          .pipe(
            map(() => {
              this.email = payload.email.email;
              this.password = payload.password.password;
              return userActions.registerUserSuccess({ payload: { email: this.email } });
            }),
            catchError((error) => of(userActions.registerUserFailure(error))),
          ),
      ),
    ),
  );

  registrationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.registerUserSuccess),
        tap(({ payload }) => {
          this.router.navigateByUrl(`/login?prefill_email=${payload.email}`);
        }),
      ),
    { dispatch: false },
  );

  registerFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.registerUserFailure),
        tap(({ error }) => {
          this.toastr.error(getErrorMessage(error), $localize`Registration error`);
        }),
      ),
    { dispatch: false },
  );

  fetchUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.fetchUser),
      mergeMap(() =>
        this.userService.fetchCurrentUser().pipe(
          map((user) => userActions.fetchUserSuccess({ user })),
          catchError((error) => of(userActions.fetchUserFailure(error))),
        ),
      ),
    ),
  );

  fetchUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.fetchUserSuccess),
      map(() => addressActions.fetchAddresses()),
    ),
  );

  fetchUserWithConsultant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.fetchUserWithConsultant),
      mergeMap(() =>
        this.userService.fetchCurrentUser().pipe(
          concatLatestFrom(() => this.store$.select(selectCurrentConsultant)),
          mergeMap(([user, currentConsultant]) => {
            if (currentConsultant || !user.activeConsultantProwessId) {
              return of(userActions.fetchUserSuccess({ user }));
            } else {
              return this.consultantService
                .fetchConsultantById(user.activeConsultantProwessId, false)
                .pipe(
                  map((consultant) =>
                    userActions.fetchUserWithConsultantSuccess({ user, consultant }),
                  ),
                );
            }
          }),
          catchError((error) => of(userActions.fetchUserWithConsultantFailure(error))),
        ),
      ),
    ),
  );

  fetchUserWithConsultantSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.fetchUserWithConsultantSuccess),
      switchMap(({ consultant }) => [
        consultantActions.fetchCurrentConsultantSuccess({
          consultant,
          isPartyEnabled: this.appInitService.Settings.ec.isPartyEnabled,
        }),
        addressActions.fetchAddresses(),
      ]),
    ),
  );

  fetchUserWithConsultantFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.fetchUserWithConsultantFailure),
        tap(() =>
          this.toastr.error($localize`Failed`, $localize`Fetching user with consultant data`),
        ),
      ),
    { dispatch: false },
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.updateUser),
      mergeMap(({ user }) =>
        this.userService.updateUser(user).pipe(
          map((newUser) => userActions.updateUserSuccess({ user: newUser })),
          catchError((error) => of(userActions.updateUserFailure(error))),
        ),
      ),
    ),
  );

  updateUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.updateUserSuccess),
        tap(() => this.toastr.success($localize`Success`, $localize`Update profile`)),
      ),
    { dispatch: false },
  );

  updateUserFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.updateUserFailure),
        tap(({ error }) => this.toastr.error(getErrorMessage(error), $localize`Update user`)),
      ),
    { dispatch: false },
  );

  updateAssignedConsultant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.updateAssignedConsultant),
      mergeMap(({ consultantProwessId }) =>
        this.userService.updateAssignedConsultant(consultantProwessId).pipe(
          map(() => userActions.updateAssignedConsultantSuccess()),
          catchError(() => of(userActions.updateAssignedConsultantFailure())),
        ),
      ),
    ),
  );

  updateAssignedConsultantSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.updateAssignedConsultantSuccess),
        tap(() => this.toastr.success($localize`Success`, $localize`Update Assigned consultant`)),
      ),
    { dispatch: false },
  );

  updateAssignedConsultantFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.updateAssignedConsultantFailure),
        tap(() => this.toastr.error($localize`Failed`, $localize`Update Assigned consultant`)),
      ),
    { dispatch: false },
  );

  /** Password reset */
  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.resetPassword),
      mergeMap(({ email }) =>
        this.userService.resetPassword(email).pipe(
          map(() => userActions.resetPasswordSuccess()),
          catchError(() => of(userActions.resetPasswordFailure())),
        ),
      ),
    ),
  );

  resetPasswordSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.resetPasswordSuccess),
        tap(() => this.toastr.success($localize`Success`, $localize`Password reset success`)),
      ),
    { dispatch: false },
  );

  resetPasswordFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.resetPasswordFailure),
        tap(() => this.toastr.success($localize`Failed`, $localize`Password reset failed`)),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private consultantService: ConsultantService,
    private appInitService: AppInitService,
    private toastr: ToastrService,
    private store$: Store<AppState>,
    private authService: AuthService,
    private router: Router,
  ) {}
}
