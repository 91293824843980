import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AddressState } from './address-state-models';

export const addressStateFeatureKey = 'addressState';

export const selectAddressFeature = createFeatureSelector<AddressState>(addressStateFeatureKey);

export const selectAddresses = createSelector(selectAddressFeature, (state: AddressState) =>
  state ? state.addresses : [],
);

export const selectLoading = createSelector(selectAddressFeature, (state: AddressState) =>
  state ? state.loading : false,
);

export const selectCountryStateNames = createSelector(selectAddressFeature, (state: AddressState) =>
  state ? state.countryStates.map((s) => s.name) : [],
);

export const selectCountryStates = createSelector(selectAddressFeature, (state: AddressState) =>
  state ? state.countryStates : [],
);

export const selectAddressValidation = createSelector(selectAddressFeature, (state: AddressState) =>
  state ? state.modals.addressValidation : null,
);

export const selectSaveInProgress = createSelector(selectAddressFeature, (state: AddressState) =>
  state ? state.saveInProgress : false,
);
