import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export enum AuthActionTypes {
  logInSuccess = '[Auth] LogIn success',
  logInFailed = '[Auth] LogIn fail',

  logOutSuccess = '[Auth] Logout success',

  setAuthLoading = '[Auth] Set Auth state loading',
  setIsAuthenticated = '[Auth] Set IsAuthenticated',
}

export const logInSuccess = createAction(
  AuthActionTypes.logInSuccess,
  props<{ isNewLogin: boolean }>(),
);

export const logInFailed = createAction(
  AuthActionTypes.logInFailed,
  props<{ error: HttpErrorResponse }>(),
);

export const logOutSuccess = createAction(AuthActionTypes.logOutSuccess);

export const setAuthLoading = createAction(
  AuthActionTypes.setAuthLoading,
  props<{ loading: boolean }>(),
);

export const setIsAuthenticated = createAction(
  AuthActionTypes.setIsAuthenticated,
  props<{ isAuthenticated: boolean }>(),
);
