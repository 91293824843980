import { Injectable } from '@angular/core';
import { PhExceptionErrorType } from '@core/enums/ph-exception-error-type.enum';
import { CheckoutService } from '@core/services/checkout.service';
import { GtmService } from '@core/services/gtm.service';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getErrorMessage } from '@shared/utils/get-error-message';
import { getErrorTitle } from '@shared/utils/get-error-title';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AppState } from '..';
import { selectCart } from '../cart';
import { selectAssignedConsultant, selectCurrentConsultant } from '../consultant';
import {
  fetchAssignedConsultantOnCheckout,
  fetchCurrentConsultantByVanityName,
  setIsOutdatedData,
} from '../consultant/consultant.actions';
import * as checkoutActions from './checkout.actions';

@Injectable()
export class CheckoutEffects {
  updatePersonalInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkoutActions.updatePersonalInfo),
      mergeMap((address) =>
        this.checkoutService
          .addressValidation({
            skipAddressVerification: address.skipAddressVerification,
            shippingAddress: address.personalInfo.shippingAddress,
            billingCustomer: address.personalInfo.billingCustomer,
          })
          .pipe(
            map((res) => {
              if (!res.saveSuccessful) {
                return checkoutActions.checkoutAddressValidation({ addressValidation: res });
              }
              return checkoutActions.updatePersonalInfoSuccess({
                personalInfo: address.personalInfo,
              });
            }),
            catchError((error) => of(checkoutActions.updatePersonalInfoFailure(error))),
          ),
      ),
    ),
  );

  updatePersonalInfoSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkoutActions.updatePersonalInfoSuccess),
      concatLatestFrom(() => [
        this.store$.select(selectCart),
        this.store$.select(selectCurrentConsultant),
        this.store$.select(selectAssignedConsultant),
      ]),
      tap(([, cart]) => this.gtmService.addShippingInfo(cart)),
      map(([, , currentConsultant, assignedConsultant]) => {
        if (
          currentConsultant?.beeNumber === assignedConsultant?.beeNumber &&
          currentConsultant &&
          assignedConsultant
        ) {
          return fetchAssignedConsultantOnCheckout();
        } else if (currentConsultant) {
          return fetchCurrentConsultantByVanityName({
            vanityName: currentConsultant.vanityName,
            filterCountry: true,
          });
        } else {
          return setIsOutdatedData({ isOutdatedData: false });
        }
      }),
    ),
  );

  updatePersonalInfoFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(checkoutActions.updatePersonalInfoFailure),
        tap(({ error }) => {
          if (error.ErrorType !== PhExceptionErrorType.ForbiddenZipCodeException) {
            this.toastr.error(getErrorMessage(error), getErrorTitle(error));
          }
        }),
      ),
    { dispatch: false },
  );

  checkoutAddressValidation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkoutActions.checkoutAddressValidation),
      filter(
        ({ addressValidation }) =>
          !addressValidation?.shippingAddressVerificationResult?.correctedAddress &&
          addressValidation?.shippingAddressVerificationResult?.isValid === false,
      ),
      map(() => checkoutActions.cancelPersonalInfoUpdate()),
    ),
  );

  checkEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkoutActions.checkUserByEmail),
      switchMap(({ email }) =>
        this.checkoutService.checkEmail(email).pipe(
          map(() => checkoutActions.checkUserByEmailSuccess()),
          catchError((error) => of(checkoutActions.checkUserByEmailFailure(error))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private gtmService: GtmService,
    private toastr: ToastrService,
    private checkoutService: CheckoutService,
  ) {}
}
