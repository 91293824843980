import { Injectable } from '@angular/core';
import { AppcuesEvent } from '@core/constants/appcues-event';
import { AppcuesService } from '@core/services/appcues.service';
import { WishlistService } from '@core/services/wishlist.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { getErrorMessage } from '@shared/utils/get-error-message';
import { getErrorTitle } from '@shared/utils/get-error-title';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, tap } from 'rxjs/operators';
import * as wishlistActions from './wishlist.actions';

@Injectable()
export class WishlistEffects {
  fetchWishlist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(wishlistActions.fetchWishlist),
      mergeMap(() =>
        this.wishlistService.fetchWishlist().pipe(
          map((res) => wishlistActions.fetchWishlistSuccess({ wishListItems: res.wishListItems })),
          catchError((error) => of(wishlistActions.fetchWishlistFailure({ error }))),
        ),
      ),
    ),
  );

  fetchWishlistFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(wishlistActions.fetchWishlistFailure),
        tap(({ error }) => {
          this.toastr.error(getErrorMessage(error), getErrorTitle(error));
        }),
      ),
    { dispatch: false },
  );

  addToWishlist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(wishlistActions.addToWishList),
      mergeMap(({ sku }) =>
        this.wishlistService.addToWishList(sku).pipe(
          map((wishListResponse) => wishlistActions.addToWishlistSuccess({ wishListResponse })),
          catchError((error) => of(wishlistActions.addToWishlistFailure({ error }))),
        ),
      ),
    ),
  );

  addToWishlistSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(wishlistActions.addToWishlistSuccess),
        tap(() => {
          this.appcuesService.track(AppcuesEvent.AddItemToWishlist);
        }),
      ),
    { dispatch: false },
  );

  addToWishlistFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(wishlistActions.addToWishlistFailure),
        tap(({ error }) => {
          this.toastr.error(getErrorMessage(error), getErrorTitle(error));
        }),
      ),
    { dispatch: false },
  );

  mergeWishlist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(wishlistActions.mergeWishlist),
      mergeMap(() =>
        this.wishlistService.mergeWishlist().pipe(
          map(() => wishlistActions.mergeWishlistSuccess()),
          catchError((error) => of(wishlistActions.mergeWishlistFailure(error))),
        ),
      ),
    ),
  );

  mergeWishlistSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(wishlistActions.mergeWishlistSuccess),
      map(() => wishlistActions.fetchWishlist()),
    ),
  );

  deleteFromWishlist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(wishlistActions.deleteFromWishlist),
      mergeMap((sku) =>
        this.wishlistService.deleteFromWishList(sku.sku).pipe(
          map((wishListResponse) =>
            wishlistActions.deleteFromWishlistSuccess({ wishListResponse }),
          ),
          catchError((error) => of(wishlistActions.deleteFromWishlistFailure({ error }))),
        ),
      ),
    ),
  );

  deleteFromWishlistFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(wishlistActions.deleteFromWishlistFailure),
        tap(({ error }) => {
          this.toastr.error(getErrorMessage(error), getErrorTitle(error));
        }),
      ),
    { dispatch: false },
  );

  newWishlist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(wishlistActions.newWishlist),
      exhaustMap(() =>
        this.wishlistService.newWishlist().pipe(
          map(() => wishlistActions.newWishlistSuccess()),
          catchError(() => of(wishlistActions.newWishlistFailure())),
        ),
      ),
    ),
  );

  newWishlistSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(wishlistActions.newWishlistSuccess),
      map(() => wishlistActions.resetWishlistState()),
    ),
  );

  constructor(
    private actions$: Actions,
    private wishlistService: WishlistService,
    private toastr: ToastrService,
    private appcuesService: AppcuesService,
  ) {}
}
