/* eslint-disable @typescript-eslint/naming-convention */
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Locale } from '@core/enums/locale';
import { environment } from '@env';
import { getLanguage } from '@shared/utils/locale-utils';
import { B2C_SIGNIN_TARGET_ROUTE } from 'app/auth-config';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private redirectUri: string;

  constructor(
    private msalService: MsalService,
    @Inject(LOCALE_ID) private localeId: Locale,
  ) {
    this.redirectUri =
      this.localeId === Locale.En
        ? `/${B2C_SIGNIN_TARGET_ROUTE}`
        : `/${this.localeId}/${B2C_SIGNIN_TARGET_ROUTE}`;
  }

  setActiveAccount(): void {
    const accounts = this.msalService.instance.getAllAccounts();
    const hasActiveAccount = this.msalService.instance.getActiveAccount();

    if (accounts.length && !hasActiveAccount) {
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  logIn(extraQueryParameters: { [key: string]: string } = {}): void {
    this.msalService.instance.loginRedirect({
      scopes: ['openid', 'offline_access', environment.b2c.customerScope],
      extraQueryParameters: { ui_locales: getLanguage(this.localeId), ...extraQueryParameters },
      redirectUri: this.redirectUri,
    });
  }

  logOut(): void {
    this.msalService.logoutPopup();
  }

  refreshToken(): void {
    this.msalService.acquireTokenSilent({
      scopes: ['openid'],
    });
  }
}
