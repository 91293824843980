import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { ReturnDetailsDto, ReturnsDto, SubmitReturnDto } from '@core/dto/return.dto';
import { Locale } from '@core/enums/locale';
import { environment } from '@env';
import { getCulture } from '@shared/utils/locale-utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReturnService {
  baseUrl: string = environment.ecommerceBackendUri;
  culture: Locale;

  constructor(private http: HttpClient, @Inject(LOCALE_ID) private localeId: Locale) {
    this.culture = getCulture(localeId);
  }

  fetchReturnHistory(): Observable<ReturnsDto> {
    return this.http.get<ReturnsDto>(`${this.baseUrl}api/order/returns`, {
      params: new HttpParams({
        fromObject: { culture: this.culture },
      }),
    });
  }

  fetchReturnDetails(returnId: string): Observable<ReturnDetailsDto> {
    return this.http.get<ReturnDetailsDto>(`${this.baseUrl}api/order/return/${returnId}`, {
      params: new HttpParams({
        fromObject: { culture: this.culture },
      }),
    });
  }

  submitReturn(request: SubmitReturnDto): Observable<any> {
    const requestWithCountry: SubmitReturnDto = {
      ...request,
      shippingAddress: { ...request.shippingAddress, country: environment.country },
    };
    return this.http.post<any>(`${this.baseUrl}api/order/return`, requestWithCountry);
  }
}
