import produce from 'immer';
import { UserState } from './user-state-models';
import { UserActionTypes } from './user.actions';

export const initialState: UserState = {
  loading: false,
  user: {
    firstName: null,
    lastName: null,
    email: null,
    phoneNumber: null,
    phoneNumberValidated: false,
    newsletters: false,
    updates: false,
    activeConsultantProwessId: null,
  },
  passwordResetSent: false,
  registrationFailedErrorType: null,
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case UserActionTypes.resetUserStore:
      return initialState;

    case UserActionTypes.fetchUser:
    case UserActionTypes.registerUser:
    case UserActionTypes.resetPassword:
      draft.loading = true;
      return draft;

    case UserActionTypes.fetchUserSuccess:
      draft.loading = false;
      draft.user = { ...draft.user, ...action.user };
      return draft;

    case UserActionTypes.updateUser:
      draft.loading = true;
      draft.user = { ...draft.user, ...action.user };
      return draft;

    case UserActionTypes.updateUserSuccess:
      draft.loading = false;
      draft.user = { ...draft.user, ...action.user };
      return draft;

    case UserActionTypes.fetchUserWithConsultant:
      draft.loading = true;
      return draft;

    case UserActionTypes.fetchUserWithConsultantSuccess:
      draft.loading = false;
      draft.user = { ...draft.user, ...action.user };
      return draft;

    case UserActionTypes.resetPasswordSuccess:
      draft.loading = false;
      draft.passwordResetSent = true;
      return;

    case UserActionTypes.resetPasswordResetState:
      draft.passwordResetSent = initialState.passwordResetSent;
      return;

    case UserActionTypes.updateUserFailure:
    case UserActionTypes.fetchUserFailure:
    case UserActionTypes.fetchUserWithConsultantFailure:
    case UserActionTypes.registerUserSuccess:
    case UserActionTypes.resetPasswordFailure:
      draft.loading = false;
      return draft;

    case UserActionTypes.registerUserFailure:
      draft.loading = false;
      draft.registrationFailedErrorType = action.error.ErrorType;
      return draft;

    case UserActionTypes.resetRegistrationFailedErrorType:
      draft.registrationFailedErrorType = initialState.registrationFailedErrorType;
      return;

    default:
      return draft;
  }
}, initialState);
