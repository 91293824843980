import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AddressValues, CheckoutAddress } from '@core/models/checkout.model';
import { AddressVerification } from '@core/store/address/address-state-models';
import { SelectOption } from '@shared/components/select/select.component';
import { isMexEnv } from '@shared/utils/environment-utils';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-shipping-address-form',
  templateUrl: './shipping-address-form.component.html',
  styleUrls: ['../../checkout-slide.shared.scss', './shipping-address-form.component.scss'],
})
export class ShippingAddressFormComponent {
  @Input() title: string;
  @Input() isAuthenticated: boolean;
  @Output() addressChange: EventEmitter<void> = new EventEmitter<void>();
  @Input() countryStates$: Observable<SelectOption[]>;
  @Input() id: string;
  @Input() addressVerification: AddressVerification;
  addressFormGroup: FormGroup;

  readonly isMexEnv = isMexEnv;
  readonly AddressLine1 = 'addressLine1';
  readonly AddressLine2 = 'addressLine2';
  readonly AddressLine3 = 'addressLine3';
  readonly City = 'city';
  readonly State = 'state';
  readonly ZipCode = 'zipCode';
  readonly Country = 'country';

  constructor(private fb: FormBuilder) {}

  createGroup(form: CheckoutAddress): FormGroup {
    this.addressFormGroup = this.fb.group({
      ...form.address,
    });
    return this.addressFormGroup;
  }

  patchValue(value: AddressValues): void {
    this.addressFormGroup.patchValue(value);
  }

  selectAddress(): void {
    this.addressChange.emit();
  }
}
