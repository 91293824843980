import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { AppState } from '@core/store';
import {
  logInFailed,
  logInSuccess,
  logOutSuccess,
  setAuthLoading,
} from '@core/store/auth/auth.actions';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-auth-initializer',
  template: '',
})
export class AuthInitializerComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  eventSubscription: Subscription = new Subscription();

  constructor(
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private store$: Store<AppState>,
  ) {}

  ngOnInit() {
    this.initAuthEvents();
    this.dispatchLoginState();
  }

  ngOnDestroy() {
    this.eventSubscription.unsubscribe();
  }

  private initAuthEvents() {
    this.msalService.instance.enableAccountStorageEvents();

    this.eventSubscription.add(
      this.msalBroadcastService.inProgress$.subscribe((status: InteractionStatus) => {
        switch (status) {
          case InteractionStatus.None:
            this.store$.dispatch(setAuthLoading({ loading: false }));
            break;

          default:
            this.store$.dispatch(setAuthLoading({ loading: true }));
            break;
        }
      }),
    );

    this.eventSubscription.add(
      this.msalBroadcastService.msalSubject$.subscribe((event: EventMessage) => {
        switch (event.eventType) {
          case EventType.LOGIN_SUCCESS:
          case EventType.ACCOUNT_ADDED:
            this.dispatchLoginState();
            break;

          case EventType.SSO_SILENT_SUCCESS:
            this.dispatchLoginState(false);
            break;

          case EventType.LOGOUT_SUCCESS:
          case EventType.ACCOUNT_REMOVED:
            this.store$.dispatch(logOutSuccess());
            break;

          default:
            break;
        }
      }),
    );
  }

  private dispatchLoginState(isNewLogin: boolean = true) {
    if (this.isAccountExist()) {
      this.store$.dispatch(logInSuccess({ isNewLogin }));
    } else {
      this.store$.dispatch(logInFailed({ error: null }));
    }
  }

  private isAccountExist(): boolean {
    return this.msalService.instance.getAllAccounts().length > 0;
  }
}
