import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CartRoutingModule } from '@cart/cart-routing.module';
import { PaymentModule } from '@payment/payment.module';
import { SharedModule } from '@shared/shared.module';
import { ConsultantModule } from '../consultant/consultant.module';
import { AddToCartConfirmationComponent } from './components/add-to-cart-confirm-dialog/add-to-cart-confirm-dialog.component';
import { CartItemUpdatedWarningModalComponent } from './components/cart-item-updated-warning-modal/cart-item-updated-warning-modal.component';
import { CartPageComponent } from './components/cart-page/cart-page.component';
import { CartPromotionMessageComponent } from './components/cart-promotion-message/cart-promotion-message.component';
import { CartTotalsComponent } from './components/cart-totals/cart-totals.component';
import { CheckoutOrderConfirmationComponent } from './components/checkout-page/checkout-order-confirmation/checkout-order-confirmation.component';
import { CheckoutRegisterUserComponent } from './components/checkout-page/checkout-order-confirmation/checkout-register-user/checkout-register-user.component';
import { CheckoutOrderSummaryComponent } from './components/checkout-page/checkout-order-summary/checkout-order-summary.component';
import { FailedOrderItemComponent } from './components/checkout-page/checkout-order-summary/failed-order-submit-modal/failed-order-item/failed-order-item.component';
import { FailedOrderSubmitModalComponent } from './components/checkout-page/checkout-order-summary/failed-order-submit-modal/failed-order-submit-modal.component';
import { PartyBoxComponent } from './components/checkout-page/checkout-order-summary/party-box/party-box.component';
import { CheckoutPageComponent } from './components/checkout-page/checkout-page.component';
import { BillingAddressFormComponent } from './components/checkout-page/checkout-payment/billing-address-form/billing-address-form.component';
import { CheckoutPaymentComponent } from './components/checkout-page/checkout-payment/checkout-payment.component';
import { CheckoutVoucherComponent } from './components/checkout-page/checkout-payment/checkout-voucher/checkout-voucher.component';
import { SavedPaypalPaymentComponent } from './components/checkout-page/checkout-payment/saved-paypal-payment/saved-paypal-payment.component';
import { CheckoutPersonalInfoComponent } from './components/checkout-page/checkout-personal-info/checkout-personal-info.component';
import { LoginModalComponent } from './components/checkout-page/checkout-personal-info/login-modal/login-modal.component';
import { MyAddressPopupComponent } from './components/checkout-page/checkout-personal-info/my-address-popup/my-address-popup.component';
import { ShippingAddressFormComponent } from './components/checkout-page/checkout-personal-info/shipping-address-form/shipping-address-form.component';
import { DeleteFromCartConfirmDialogComponent } from './components/delete-from-cart-confirm-dialog/delete-from-cart-confirm-dialog.component';
import { OrderLineComponent } from './components/order-line/order-line.component';
import { OrderLinesComponent } from './components/order-lines/order-lines.component';

@NgModule({
  declarations: [
    CartPageComponent,
    AddToCartConfirmationComponent,
    DeleteFromCartConfirmDialogComponent,
    CheckoutPageComponent,
    CheckoutPersonalInfoComponent,
    CheckoutPaymentComponent,
    CheckoutOrderSummaryComponent,
    CheckoutOrderConfirmationComponent,
    OrderLineComponent,
    OrderLinesComponent,
    CartTotalsComponent,
    ShippingAddressFormComponent,
    BillingAddressFormComponent,
    MyAddressPopupComponent,
    LoginModalComponent,
    PartyBoxComponent,
    CheckoutRegisterUserComponent,
    CartPromotionMessageComponent,
    CheckoutVoucherComponent,
    FailedOrderSubmitModalComponent,
    FailedOrderItemComponent,
    CartItemUpdatedWarningModalComponent,
    SavedPaypalPaymentComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    CartRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    PaymentModule,
    ConsultantModule,
  ],
  exports: [CartPageComponent, AddToCartConfirmationComponent, CheckoutPageComponent],
})
export class CartModule {}
