import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-back-forth-buttons',
  templateUrl: './back-forth-buttons.component.html',
  styleUrls: ['./back-forth-buttons.component.scss'],
})
export class BackForthButtonsComponent {
  @Output() navigateBack: EventEmitter<void> = new EventEmitter();
  @Output() navigateForward: EventEmitter<void> = new EventEmitter();
  @Input() nextProcessing: boolean;
  @Input() isFinalStep: boolean;
  @Input() isBackButtonDisabled: boolean;

  readonly Next: string = $localize`Next`;
  readonly Finalize: string = $localize`Finalize`;

  constructor() {}

  previousStepClick(): void {
    if (!this.nextProcessing) {
      this.navigateBack.emit();
    }
  }

  nextStepClick(): void {
    if (!this.nextProcessing) {
      this.navigateForward.emit();
    }
  }
}
