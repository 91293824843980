import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalConfig } from '@core/models/modal-config.model';
import { AppState } from '@core/store';
import { shareInEmail } from '@core/store/share/share.actions';
import { selectUser } from '@core/store/user';
import { Store } from '@ngrx/store';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

enum emailFormKey {
  to = 'to',
  yourName = 'yourName',
  friendName = 'friendName',
  subject = 'subject',
  message = 'message',
}

@Component({
  selector: 'app-email-share-modal',
  templateUrl: './email-share-modal.component.html',
  styleUrls: ['./email-share-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailShareModalComponent implements OnInit, OnDestroy {
  readonly emailFormKey = emailFormKey;
  modalConfig: ModalConfig = {
    title: $localize`Email a friend`,
    hideCloseButton: true,
    hideDismissButton: true,
  };
  subscriptions: Subscription = new Subscription();
  emailFormGroup: FormGroup;

  @ViewChild('modal') private modal: ModalComponent;

  constructor(
    private fb: FormBuilder,
    private store$: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.emailFormGroup = this.fb.group({
      [emailFormKey.to]: ['', [Validators.required, Validators.email]],
      [emailFormKey.yourName]: ['', [Validators.required]],
      [emailFormKey.friendName]: ['', [Validators.required]],
      [emailFormKey.subject]: ['', [Validators.required]],
      [emailFormKey.message]: [''],
    });

    this.subscriptions.add(
      this.store$
        .select(selectUser)
        .pipe(filter((user) => !!user.email))
        .subscribe((user) => {
          this.emailFormGroup.patchValue({
            [emailFormKey.yourName]: `${user.firstName} ${user.lastName}`,
          });
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  open() {
    this.modal.open();
  }

  sendEmail() {
    this.store$.dispatch(
      shareInEmail({
        payload: {
          name: this.emailFormGroup.value[emailFormKey.yourName],
          friendName: this.emailFormGroup.value[emailFormKey.friendName],
          url: window.location.href,
          emailTo: this.emailFormGroup.value[emailFormKey.to],
          subject: this.emailFormGroup.value[emailFormKey.subject],
          message: this.emailFormGroup.value[emailFormKey.message],
        },
      }),
    );
    this.modal.close();
  }
}
