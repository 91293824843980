import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CardPaymentTypes } from '@core/constants/payment.consts';
import { AddPaymentToPartyOrderRequest } from '@core/dto/order.dto';
import { PartyOrderPaymentPages } from '@core/enums/party-order-payment-pages.enum';
import { EcPaymentHandlerType, PaymentHandlerType } from '@core/enums/payment-handler-type.enum';
import { PaymentProviderType } from '@core/enums/payment-provider-type.enum';
import { PaymentAmountType, PaymentCardType, PaymentType } from '@core/enums/payment-type.enum';
import { CardholderName } from '@core/models/cardholder-name.model';
import { LogCardAttemptData } from '@core/models/log-card-attempt-data.model';
import { RiskData } from '@core/models/risk-data.model';
import { AppInitService } from '@core/services/app-init.service';
import { AppState } from '@core/store';
import {
  selectOrderStateLoading,
  selectPartyOrderData,
  selectPartyOrderStatus,
} from '@core/store/order';
import { PartyOrderData, SubmittedOrderStatus } from '@core/store/order/order-state-models';
import {
  resetPartyOrderFetched,
  storePartyOrderDataForPayPalOrder,
  submitPartyOrder,
} from '@core/store/order/order.actions';
import { selectPartyOrderCustomerId, selectPaymentInfo } from '@core/store/payment';
import { Store } from '@ngrx/store';
import { CardPaymentWrapperComponent } from '@payment/components/card-payment-wrapper/card-payment-wrapper.component';
import { PaymentHandlerBase } from '@payment/payment-handler/payment-handler-base.model';
import { PaymentHandlerFactory } from '@payment/payment-handler/payment-handler-factory';
import {
  PaymentTypeOptionsAndImages,
  getPaymentProviderType,
  initPaymentTypeOptionsAndImages,
} from '@payment/payment-handler/payment-provider.utils';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { SelectOption } from '@shared/components/select/select.component';
import { isMexEnv, isUsaEnv } from '@shared/utils/environment-utils';
import { Observable } from 'rxjs';
import { filter, take, tap, withLatestFrom } from 'rxjs/operators';

enum PaymentInfoFormKey {
  paymentTypeSelector = 'paymentTypeSelector',
  balanceAmountSelector = 'balanceAmountSelector',
  otherPaymentAmount = 'otherPaymentAmount',
  fullPaymentAmount = 'fullPaymentAmount',
}

@Component({
  selector: 'app-party-order-payment-info',
  templateUrl: './party-order-payment-info.component.html',
  styleUrls: ['./party-order-payment-info.component.scss'],
})
export class PartyOrderPaymentInfoComponent extends BaseComponent implements OnInit, OnDestroy {
  @Output()
  previousPage: EventEmitter<PartyOrderPaymentPages> = new EventEmitter<PartyOrderPaymentPages>();

  @Output()
  nextPage: EventEmitter<PartyOrderPaymentPages> = new EventEmitter<PartyOrderPaymentPages>();

  @Input() riskData?: RiskData;

  readonly PaymentAmountType = PaymentAmountType;
  readonly PaymentInfoFormKey = PaymentInfoFormKey;
  readonly CardPaymentTypes = CardPaymentTypes;
  readonly isMexEnv = isMexEnv;

  public paymentInfoFormGroup: FormGroup;
  public isPaymentRequestable: boolean = false;
  public isCardSaved: boolean = false;
  public isAmountOk: boolean = false;
  public isAmountGraterThanBalanceDue: boolean = false;
  public paymentHandler: PaymentHandlerBase;
  public partyOrderData: PartyOrderData;
  public showPaymentInformation: boolean = false;
  public showCardPaymentComponent: boolean = false;
  public selectedPaymentAmountType: PaymentAmountType;
  public loading$: Observable<boolean>;
  public selectedCardPaymentType: PaymentCardType = PaymentCardType.DebitCard;
  public isBackToAddressPage: boolean = false;
  public paymentProviderType: PaymentProviderType;
  public cardholderName: CardholderName;
  public selectedPaymentType: PaymentType;
  public paymentTypeOptions: SelectOption[] = [];
  public availablePaymentMethodsImgSrc: string[] = [];
  public isSubmitInProgress: boolean = false;

  @ViewChild('cardPaymentWrapper')
  private paymentWrapperComponent: CardPaymentWrapperComponent;

  constructor(
    private appInitService: AppInitService,
    private fb: FormBuilder,
    private store$: Store<AppState>,
    private cdRef: ChangeDetectorRef,
    private paymentHandlerFactory: PaymentHandlerFactory,
  ) {
    super();

    this.paymentHandler = this.paymentHandlerFactory.getPaymentHandler(
      PaymentHandlerType.ECommerce,
      EcPaymentHandlerType.PartyOrder,
    );

    this.isBackToAddressPage = this.appInitService.Settings.ec.partyOrderCollectRiskDataFromInput;
  }

  get isSubmitDisabled(): boolean {
    return !(this.isPaymentRequestable && this.isAmountOk && !this.isSubmitInProgress);
  }

  get showPaymentProvider() {
    if (isUsaEnv || this.selectedPaymentType === PaymentType.CreditOrDebitCard) {
      return this.showPaymentInformation;
    }

    if (this.selectedPaymentType === PaymentType.PayPal) {
      return this.showPaymentInformation && this.isAmountOk;
    }

    return false;
  }

  ngOnInit(): void {
    this.cardholderName = {
      firstName: this.riskData?.firstName,
      lastName: this.riskData?.lastName,
    };
    this.paymentInfoFormGroup = this.createFormGroup();
    this.loading$ = this.store$.select(selectOrderStateLoading);
    this.initPaymentTypeElements();

    this.subscriptions.add(
      this.store$
        .select(selectPartyOrderData)
        .pipe(
          filter((partyOrderData) => !!partyOrderData),
          take(1),
        )
        .subscribe((partyOrderData) => {
          this.partyOrderData = partyOrderData;
          this.showPaymentInformation = partyOrderData.balanceDue > 0;
          this.paymentInfoFormGroup.patchValue({
            [PaymentInfoFormKey.fullPaymentAmount]: partyOrderData.balanceDue,
          });
        }),
    );

    this.subscriptions.add(
      this.store$
        .select(selectPartyOrderStatus)
        .pipe(
          filter(
            (status) =>
              status == SubmittedOrderStatus.success || status == SubmittedOrderStatus.failure,
          ),
        )
        .subscribe(() => {
          this.nextPage.emit(PartyOrderPaymentPages.PaymentConfirmation);
        }),
    );

    this.subscriptions.add(
      this.paymentInfoFormGroup
        .get(PaymentInfoFormKey.otherPaymentAmount)
        .valueChanges.pipe(
          filter(() => this.selectedPaymentAmountType === PaymentAmountType.Custom),
        )
        .subscribe((value) => {
          if (value > 0) {
            this.isAmountOk = true;

            if (isMexEnv) {
              this.storeDataPayPalOrder();
            }
          } else {
            this.isAmountOk = false;
          }
        }),
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.paymentHandler.resetToken();
  }

  onChangePaymentAmountType(newValue: PaymentAmountType): void {
    this.selectedPaymentAmountType = newValue;

    const otherPaymentControl = this.paymentInfoFormGroup.get(
      PaymentInfoFormKey.otherPaymentAmount,
    );
    if (this.selectedPaymentAmountType == PaymentAmountType.Custom) {
      otherPaymentControl.enable();
    } else {
      otherPaymentControl.setValue(0);
      otherPaymentControl.disable();
      this.isAmountOk = true;

      if (isMexEnv) {
        this.storeDataPayPalOrder();
      }
    }
  }

  onPaymentTypeChange(newPaymentType: PaymentType): void {
    const previousPaymentType = this.selectedPaymentType;
    this.resetCardPayment(previousPaymentType);
    this.paymentProviderType = getPaymentProviderType(newPaymentType);
    this.selectedPaymentType = newPaymentType;
  }

  onSubmit(): void {
    if (this.partyOrderData) {
      let paymentAmount: number = this.getAmount();

      this.isAmountGraterThanBalanceDue =
        this.selectedPaymentAmountType == PaymentAmountType.Custom &&
        paymentAmount > this.partyOrderData.balanceDue;

      if (!this.isAmountGraterThanBalanceDue) {
        this.isAmountGraterThanBalanceDue = false;

        this.submitPartyOrderPayment(paymentAmount);
        this.isSubmitInProgress = true;
      }
    }
  }

  onCancel(): void {
    this.store$.dispatch(resetPartyOrderFetched());
    this.previousPage.emit(PartyOrderPaymentPages.SearchPage);
  }

  onBackToBillingAddressPage(): void {
    this.previousPage.emit(PartyOrderPaymentPages.AddressPage);
  }

  storeDataPayPalOrder() {
    this.store$.dispatch(
      storePartyOrderDataForPayPalOrder({
        payload: {
          masterOrderId: this.partyOrderData.orderNumber,
          amount: this.getAmount(),
        },
      }),
    );
  }

  getAmount() {
    switch (this.selectedPaymentAmountType) {
      case PaymentAmountType.Balance:
        return this.partyOrderData.balanceDue;
      case PaymentAmountType.Custom:
        const customPaymentAmount = this.paymentInfoFormGroup.get(
          PaymentInfoFormKey.otherPaymentAmount,
        ).value;
        return Number(customPaymentAmount);
    }
  }

  handlePaymentRequestable(isPaymentMethodRequestable: boolean): void {
    this.isPaymentRequestable = isPaymentMethodRequestable;
    // https://github.com/webcomponents/polyfills/issues/238
    this.cdRef.detectChanges();
  }

  handleCardAttempt(cardAttemptData: LogCardAttemptData): void {
    this.paymentHandler.logCardAttempt(
      cardAttemptData.success,
      cardAttemptData.response,
      this.paymentProviderType,
      this.partyOrderData.orderNumber,
    );
  }

  paymentApproved(isCreated: boolean): void {
    this.isCardSaved = isCreated;
    this.cdRef.detectChanges();
  }

  resetCardPayment(previousPaymentType: PaymentType, resetComponent: boolean = false): void {
    if (isMexEnv && previousPaymentType === PaymentType.PayPal) {
      if (resetComponent) {
        // As we have already a card payment and we would like to change it we have to reset it
        this.paymentHandler.updateOrder();
        this.resetPayPalComponent();
      }
    }
    this.paymentHandler.resetToken();
  }

  private initPaymentTypeElements(): void {
    const paymentTypeData: PaymentTypeOptionsAndImages = initPaymentTypeOptionsAndImages(false);
    this.paymentTypeOptions = paymentTypeData.paymentTypeOptions;
    this.availablePaymentMethodsImgSrc = paymentTypeData.availablePaymentMethodsImgSrc;
    this.selectedPaymentType = this.paymentTypeOptions[0].value;
    this.paymentProviderType = getPaymentProviderType(this.selectedPaymentType);
    this.paymentInfoFormGroup.patchValue({
      [PaymentInfoFormKey.paymentTypeSelector]: this.selectedPaymentType,
    });
  }

  private createFormGroup(): FormGroup {
    const formGroup = this.fb.group({
      [PaymentInfoFormKey.otherPaymentAmount]: new FormControl({ value: 0, disabled: true }),
      [PaymentInfoFormKey.fullPaymentAmount]: new FormControl({ value: 0, disabled: true }),
      [PaymentInfoFormKey.paymentTypeSelector]: new FormControl({ value: '' }),
    });

    return formGroup;
  }

  private resetPayPalComponent(): void {
    // need to force change detection for paypal component reinit
    this.isCardSaved = false;
    this.cdRef.detectChanges();

    // wait to reload the hidden payment component by resetting the error
    setTimeout(() => this.paymentWrapperComponent?.resetToken());
  }

  private submitPartyOrderPayment(paymentAmount: number) {
    const paymentMethodObject$ = this.paymentWrapperComponent.requestPaymentMethodObject().pipe(
      tap((isSuccessful) => {
        this.paymentWrapperComponent.createPaymentMethod(isSuccessful);
      }),
      take(1),
    );

    this.subscriptions.add(
      paymentMethodObject$
        .pipe(
          withLatestFrom(
            this.store$.select(selectPaymentInfo),
            this.store$.select(selectPartyOrderCustomerId),
          ),
          take(1),
        )
        .subscribe(([, paymentInfo, customerId]) => {
          const request: AddPaymentToPartyOrderRequest = {
            masterOrderId: this.partyOrderData.orderNumber,
            amount: paymentAmount,
            beeNumber: this.partyOrderData.consultantBeeNumber,
            riskData: this.riskData,
            provider: this.paymentProviderType,
            customerId,
          };

          if (isUsaEnv) {
            request.nonce = paymentInfo.nonce;
            request.deviceData = paymentInfo.deviceData;
          } else if (isMexEnv) {
            switch (this.paymentProviderType) {
              case PaymentProviderType.PayPal:
                request.paypalOrderId = paymentInfo.order.orderId;
                break;
              case PaymentProviderType.Nexio:
                request.cardSaveRequest = paymentInfo.cardSaveRequest;
                break;
            }
          }

          this.store$.dispatch(submitPartyOrder({ request }));
        }),
    );
  }
}
