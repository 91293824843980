<div class="row checkout-slide">
  <div class="col-12 mb-3">
    <app-party-box
      class="mx-auto"
      #partyBox
      [consultantParties]="consultantParties$ | async"
    ></app-party-box>
  </div>

  <div class="col summary-content">
    <div class="row d-none d-md-block">
      <h3 class="col p-md-0 title">{{ title | titlecase }}</h3>
    </div>
    <div
      *ngIf="(cart$ | async)?.orderLines.length"
      class="row font-size-smaller d-lt-none text-uppercase font-weight-bold"
    >
      <div class="col-md-5">
        <p i18n>Product(s)</p>
      </div>
      <div class="col-md-2">
        <p i18n>Price</p>
      </div>
      <div class="col-md-2">
        <p i18n>Qty</p>
      </div>
      <div class="col-md-1">
        <p i18n>Total</p>
      </div>
    </div>
    <hr *ngIf="(cart$ | async)?.orderLines.length" class="my-0 d-lt-none" />
    <div class="row">
      <app-order-lines
        class="col"
        [cart]="cart$ | async"
        [disableHeader]="true"
        [readOnly]="true"
      ></app-order-lines>
    </div>
    <div *ngIf="checkoutInfoCards$ | async as infoCards">
      <hr class="my-0 d-lt-none" />
      <div class="row">
        <app-info-card
          class="col-12 col-md-6 b-right px-3 px-md-0 pr-md-5"
          [data]="infoCards.customerInfo"
          (backToPreviousStep)="back()"
        ></app-info-card>
        <app-info-card
          class="col-12 col-md-6 px-3 px-md-0 pl-md-5"
          [data]="infoCards.shippingInfo"
          (backToPreviousStep)="back()"
        ></app-info-card>
      </div>
      <hr class="my-0 d-lt-none" />
    </div>
    <div class="row">
      <app-cart-totals class="col" [cart]="cart$"></app-cart-totals>
    </div>

    <div class="row justify-content-between mt-5">
      <div
        class="validation-error-message col-12 mb-2"
        *ngIf="submissionErrorMessage$ | async as submissionErrorMessage"
      >
        {{ submissionErrorMessage }}
      </div>
      <div
        class="validation-error-message col-12 mb-2"
        *ngIf="createVoucherErrorMessage$ | async as createVoucherErrorMessage"
      >
        {{ createVoucherErrorMessage }}
      </div>

      <div class="col col-md-5">
        <button class="btn btn-outline-dark text-uppercase w-100" (click)="back()" i18n>
          Back to Personal Info
        </button>
      </div>
      <div class="col col-md-5">
        <button
          class="btn btn-primary text-uppercase w-100"
          (click)="next()"
          [disabled]="partyBoxComponent?.partyFormGroup?.invalid"
          i18n
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</div>

<app-consultant-finder-modal
  #consultantFinder
  [zipCode]="zipCode$ | async"
  hasAutomaticAssignOption="true"
  mandatory="true"
  [consultantFinderText]="ConsultantFinderText"
  (consultantSelected)="consultantSelected()"
></app-consultant-finder-modal>
