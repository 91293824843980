import { Injectable } from '@angular/core';
import { AppcuesEvent } from '@core/constants/appcues-event';
import { LocalStorageKey } from '@core/enums/local-storage-key.enum';
import { AppcuesService } from '@core/services/appcues.service';
import { CartService } from '@core/services/cart.service';
import { GtmService } from '@core/services/gtm.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getErrorMessage } from '@shared/utils/get-error-message';
import { getErrorTitle } from '@shared/utils/get-error-title';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { selectOrderLineById } from '.';
import { AppState } from '..';
import * as cartActions from './cart.actions';

@Injectable()
export class CartEffects {
  addToCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.addToCart),
      mergeMap(({ payload }) =>
        this.cartService.addToCart(payload.sku, payload.quantity).pipe(
          tap((cart) => this.handelCartModified(cart.isModifiedByAutoUpdate)),
          map(() => cartActions.addToCartSuccess({ payload })),
          catchError((error) => of(cartActions.addToCartFailure({ error }))),
        ),
      ),
    ),
  );

  addToCartsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.addToCartSuccess),
      tap(({ payload }) => {
        this.gtmService.addToCart(payload);
        this.appcuesService.track(AppcuesEvent.AddItemToCart);
      }),
      map(() => cartActions.fetchCart()),
    ),
  );

  addToCartFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cartActions.addToCartFailure),
        tap(({ error }) => {
          this.toastr.error(getErrorMessage(error), getErrorTitle(error));
        }),
      ),
    { dispatch: false },
  );

  fetchCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.fetchCart),
      exhaustMap(() =>
        this.cartService.fetchCart().pipe(
          tap((cart) => this.handelCartModified(cart.isModifiedByAutoUpdate)),
          map((cart) => cartActions.fetchCartSuccess({ cart })),
          catchError((error) => of(cartActions.fetchCartFailure({ error }))),
        ),
      ),
    ),
  );

  updateOrderLine$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.updateOrderLineQty),
      switchMap(({ orderLineId, quantity }) =>
        this.cartService.updateOrderLine(orderLineId, quantity).pipe(
          tap((cart) => this.handelCartModified(cart.isModifiedByAutoUpdate)),
          map((cart) => cartActions.updateOrderLineQtySuccess({ cart })),
          catchError((error) => of(cartActions.updateOrderLineQtyFailure({ error }))),
        ),
      ),
    ),
  );

  mergeCarts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.mergeCarts),
      mergeMap(() =>
        this.cartService.mergeCarts().pipe(
          map(() => cartActions.mergeCartsSuccess()),
          catchError((error) => of(cartActions.mergeCartsFailure({ error }))),
        ),
      ),
    ),
  );

  mergeCartsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.mergeCartsSuccess),
      map(() => cartActions.fetchCart()),
    ),
  );

  newCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.newCart),
      exhaustMap(() =>
        this.cartService.newCart().pipe(
          map(() => cartActions.newCartSuccess()),
          catchError((error) => of(cartActions.newCartFailure({ error }))),
        ),
      ),
    ),
  );

  newCartSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.newCartSuccess),
      map(() => cartActions.resetCartState()),
    ),
  );

  deleteFromCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.deleteFromCart),
      concatLatestFrom(({ orderLineId }) => this.store$.select(selectOrderLineById(orderLineId))),
      tap(([, orderLine]) => this.gtmService.removeFromCart(orderLine)),
      mergeMap(([{ orderLineId }]) =>
        this.cartService.deleteOrderLine(orderLineId).pipe(
          tap((cart) => this.handelCartModified(cart.isModifiedByAutoUpdate)),
          map((cart) => cartActions.deleteFromCartSuccess({ cart })),
          catchError((error) => of(cartActions.deleteFromCartFailure({ error }))),
        ),
      ),
    ),
  );

  deleteFromCartFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cartActions.deleteFromCartFailure),
        tap(({ error }) => {
          this.toastr.error(getErrorMessage(error), getErrorTitle(error));
        }),
      ),
    { dispatch: false },
  );

  fetchProductsRecommendations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.fetchProductsRecommendations),
      mergeMap(({ skus }) =>
        this.cartService.fetchProductsRecommendations(skus).pipe(
          map((res) =>
            cartActions.fetchProductsRecommendationsSuccess({
              recommendations: res.recommendations,
            }),
          ),
          catchError((error) => of(cartActions.fetchProductsRecommendationsFailure(error))),
        ),
      ),
    ),
  );

  fetchProductsRecommendationFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cartActions.fetchProductsRecommendationsFailure),
        tap(({ error }) => {
          this.toastr.error(getErrorMessage(error), getErrorTitle(error));
        }),
      ),
    { dispatch: false },
  );

  addSurpriseBuy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.addSurpriseBuy),
      switchMap(() =>
        this.cartService.addSurpriseBuy().pipe(
          tap((cart) => this.handelCartModified(cart.isModifiedByAutoUpdate)),
          map((cart) => cartActions.addSurpriseBuySuccess({ cart })),
          catchError((error) => of(cartActions.addSurpriseBuyFailure({ error }))),
        ),
      ),
    ),
  );

  private handelCartModified(isCartModified): void {
    if (isCartModified) {
      this.localStorageService.setItem(LocalStorageKey.isCartUpdated, 'true');
    }
  }

  constructor(
    private store$: Store<AppState>,
    private actions$: Actions,
    private cartService: CartService,
    private toastr: ToastrService,
    private gtmService: GtmService,
    private appcuesService: AppcuesService,
    private localStorageService: LocalStorageService,
  ) {}
}
