import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from './user-state-models';

export const userStateFeatureKey = 'userState';

export const selectUserStateFeature = createFeatureSelector<UserState>(userStateFeatureKey);

export const selectUser = createSelector(selectUserStateFeature, (state: UserState) =>
  state ? state.user : null,
);

export const selectUserLoading = createSelector(selectUserStateFeature, (state: UserState) =>
  state ? state.loading : false,
);

export const selectPasswordResetSent = createSelector(selectUserStateFeature, (state: UserState) =>
  state ? state.passwordResetSent : false,
);

export const selectRegistrationFailedErrorType = createSelector(
  selectUserStateFeature,
  (state: UserState) => (state ? state.registrationFailedErrorType : null),
);
