import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserRoute } from '@core/constants/route.const';
import { CreateCardPaymentErrorType } from '@core/enums/create-card-payment-error-type.enum';
import { EcPaymentHandlerType, PaymentHandlerType } from '@core/enums/payment-handler-type.enum';
import { PaymentProviderType } from '@core/enums/payment-provider-type.enum';
import { LogCardAttemptData } from '@core/models/log-card-attempt-data.model';
import { LoggerService } from '@core/services/logger.service';
import { AppState } from '@core/store';
import { selectCreatePaymentMethodResponse, selectPaymentMethodLoading } from '@core/store/payment';
import { CreatedCardPaymentMethod } from '@core/store/payment/payment-state-models';
import {
  resetCardPaymentInfo,
  resetCreatedPaymentMethod,
  resetPaymentInfo,
} from '@core/store/payment/payment.actions';
import { Store } from '@ngrx/store';
import { CardPaymentWrapperComponent } from '@payment/components/card-payment-wrapper/card-payment-wrapper.component';
import { PaymentHandlerBase } from '@payment/payment-handler/payment-handler-base.model';
import { PaymentHandlerFactory } from '@payment/payment-handler/payment-handler-factory';
import {
  PaymentTypeOptionsAndImages,
  getPaymentProviderType,
  initPaymentTypeOptionsAndImages,
} from '@payment/payment-handler/payment-provider.utils';
import { isUsaEnv } from '@shared/utils/environment-utils';
import { Observable, Subscription } from 'rxjs';
import { filter, mergeMap, take, tap } from 'rxjs/operators';
import { PaymentMethodBaseComponent } from '../payment-method-base.component';

@Component({
  selector: 'app-create-payment-method',
  templateUrl: './create-payment-method.component.html',
  styleUrls: ['./create-payment-method.component.scss'],
})
export class CreatePaymentMethodComponent
  extends PaymentMethodBaseComponent
  implements OnInit, OnDestroy
{
  readonly BraintreeDelayAfterLoad = 600;

  paymentHandler: PaymentHandlerBase;
  paymentMethodId: string;
  isSubmitted = false;
  isSubmitDisabled = true;
  newPaymentMethodTitle = $localize`New payment method`;
  makeDefault = false;
  saveInProgress$: Observable<boolean>;
  paymentProviderType: PaymentProviderType;
  isPaymentMethodRequestable: boolean = false;

  @ViewChild('cardPaymentWrapper')
  private paymentWrapperComponent: CardPaymentWrapperComponent;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private store$: Store<AppState>,
    private paymentHandlerFactory: PaymentHandlerFactory,
    private logger: LoggerService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.saveInProgress$ = this.store$.select(selectPaymentMethodLoading);
    this.paymentHandler = this.paymentHandlerFactory.getPaymentHandler(
      PaymentHandlerType.ECommerce,
      EcPaymentHandlerType.CreatePaymentMethod,
    );
    this.initPaymentTypeElements();
  }

  ngOnDestroy(): void {
    this.store$.dispatch(resetPaymentInfo());
    this.store$.dispatch(resetCreatedPaymentMethod());
    this.store$.dispatch(resetCardPaymentInfo());
    this.subscriptions.unsubscribe();
  }

  handlePaymentRequestable(isPaymentMethodRequestable: boolean): void {
    this.isPaymentMethodRequestable = isPaymentMethodRequestable;
    // https://github.com/webcomponents/polyfills/issues/238
    this.cdRef.detectChanges();
  }

  handlePaymentProviderLoading(isLoaded: boolean) {
    if (isUsaEnv && !isLoaded) this.handleBraintreeCustomization();
  }

  resetCardPayment(): void {
    // TODO: PaymentMethod - this does not work as intended
    setTimeout(() => this.paymentWrapperComponent.resetToken());
  }

  onSave(): void {
    let isValid: boolean = false;
    this.isSubmitted = true;
    isValid = this.isPaymentMethodRequestable && !this.isCardPaymentError;
    if (isValid) {
      this.subscriptions.add(
        this.requestCardPaymentMethod().subscribe(
          (response: CreatedCardPaymentMethod) => {
            if (
              response &&
              (response.errorType == undefined ||
                response.errorType === CreateCardPaymentErrorType.None)
            ) {
              this.navigateToPaymentMethods();
            } else {
              this.logger.error('Payment method creation failed:', response?.errorType);
            }
          },
          (error) => {
            this.navigateToPaymentMethods();
            this.logger.error('Payment method creation failed:', error);
          },
        ),
      );
    }
  }

  handleCardAttempt(cardAttemptData: LogCardAttemptData): void {
    this.paymentHandler.logCardAttempt(cardAttemptData.success, cardAttemptData.response);
  }

  private initPaymentTypeElements(): void {
    const paymentTypeData: PaymentTypeOptionsAndImages = initPaymentTypeOptionsAndImages(false);
    this.paymentProviderType = getPaymentProviderType(paymentTypeData.paymentTypeOptions[0].value);
  }

  private navigateToPaymentMethods() {
    this.router.navigate([UserRoute.MyAccount, UserRoute.PaymentMethods]);
  }

  /** Requests a payment method object via dropin third party library and creates a payment method */
  private requestCardPaymentMethod(): Observable<CreatedCardPaymentMethod> {
    const paymentMethodObject$ = this.paymentWrapperComponent
      .requestPaymentMethodObject()
      .pipe(take(1));
    const listenCreateMethod$ = this.store$.select(selectCreatePaymentMethodResponse).pipe(
      filter((response: any) => !!response),
      take(1),
    );

    return paymentMethodObject$.pipe(
      tap(() => this.paymentWrapperComponent.createPaymentMethod()),
      mergeMap(() => listenCreateMethod$),
    );
  }

  private handleBraintreeCustomization() {
    this.hideCarDSelectorForBraintreeCreateCard();
    this.automateClickOnBraintreeCreateCard();
  }

  private hideCarDSelectorForBraintreeCreateCard() {
    let brainTreeMethodsContainer: HTMLElement = document.querySelector('.braintree-methods');

    if (brainTreeMethodsContainer) {
      setTimeout(() => {
        brainTreeMethodsContainer.style.display = 'none';
      }, this.BraintreeDelayAfterLoad);
    }
  }

  private automateClickOnBraintreeCreateCard() {
    let chooseAnotherWayButton: HTMLElement = document.querySelector(
      '.braintree-large-button.braintree-toggle',
    );

    if (chooseAnotherWayButton) {
      setTimeout(() => {
        chooseAnotherWayButton.click();
      }, this.BraintreeDelayAfterLoad);
    }
  }
}
