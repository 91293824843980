import produce from 'immer';
import { AuthState } from './auth-state-models';
import { AuthActionTypes } from './auth.actions';

export const initialState: AuthState = {
  loading: false,
  error: null,
  isNewLogin: false,
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case AuthActionTypes.logInFailed:
      draft.isAuthenticated = false;

      draft.error = action.error;
      return draft;

    case AuthActionTypes.logInSuccess:
      draft.isAuthenticated = true;

      draft.error = null;
      return draft;

    case AuthActionTypes.logOutSuccess:
      draft.isAuthenticated = false;
      draft.error = null;
      return draft;

    case AuthActionTypes.setAuthLoading:
      draft.loading = action.loading;
      return;

    case AuthActionTypes.setIsAuthenticated:
      draft.isAuthenticated = action.isAuthenticated;
      return;

    default:
      return draft;
  }
}, initialState);
