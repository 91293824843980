import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { ModalConfig } from '@core/models/modal-config.model';
import { Address } from '@core/store/address/address-state-models';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-my-address-popup',
  templateUrl: './my-address-popup.component.html',
  styleUrls: ['./my-address-popup.component.scss'],
})
export class MyAddressPopupComponent implements OnInit, OnDestroy {
  @Input() userAddresses: Address[];
  @Input() currentAddress$: Observable<Address>;
  @Input() onDismiss: () => void;
  @Output() addressSelect: EventEmitter<number> = new EventEmitter<number>();

  selectedAddressId: number;
  modalConfig: ModalConfig;
  @ViewChild('modal') private modalComponent: ModalComponent;

  private subscriptions: Subscription = new Subscription();

  constructor() {}

  ngOnInit(): void {
    this.modalConfig = {
      title: $localize`My saved addresses`,
      onDismiss: this.onDismiss.bind(this),
      hideCloseButton: true,
      hideDismissButton: true,
      ngbModalOptions: {
        size: 'lg',
      },
    };

    this.subscriptions.add(
      this.currentAddress$?.pipe(filter((address) => !!address)).subscribe((address) => {
        this.selectedAddressId = this.selectedId(address);
        this.userAddresses = this.sort();
        this.modalComponent.open();
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  selectedId(address: Address): number {
    return this.userAddresses.find((userAddress) => {
      const selectedAddress = {
        addressLine1: userAddress.addressLine1,
        addressLine2: userAddress.addressLine2,
        city: userAddress.city,
        country: userAddress.country,
        state: userAddress.state,
        zipCode: userAddress.zipCode,
      };
      return JSON.stringify(selectedAddress) === JSON.stringify(address);
    })?.id;
  }

  continue(): void {
    this.addressSelect.emit(this.selectedAddressId);
    this.modalComponent.dismiss();
  }

  cancel(): void {
    this.modalComponent.close();
  }

  sort(): Address[] {
    return [...this.userAddresses].sort((a, b) =>
      a.type == 'Default' ? -1 : b.type == 'Default' ? 1 : 0,
    );
  }
}
