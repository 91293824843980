<div class="container" *ngIf="personalInfo$ | async">
  <form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
    <div class="row">
      <div class="col-12 mb-3">
        <app-password-input
          [identifier]="RegisterFormKey.password"
          [formControlName]="RegisterFormKey.password"
          label="Password"
          i18n-label
          placeholder="Password"
          i18n-placeholder
        ></app-password-input>
      </div>
      <div class="col-12 mb-3">
        <app-password-input
          [identifier]="RegisterFormKey.passwordConfirm"
          [formControlName]="RegisterFormKey.passwordConfirm"
          [validGroup]="passwordMatch"
          label="Re-enter Password"
          i18n-label
          placeholder="Re-enter Password"
          i18n-placeholder
        ></app-password-input>
      </div>
      <div class="col-12 my-2">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            [formControlName]="RegisterFormKey.newsletters"
            id="newsletters"
          />
          <label i18n class="form-check-label" for="newsletters">
            Subscribe to the Princess House Newsletter
          </label>
        </div>
      </div>
      <div class="col-12 my-2">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            [formControlName]="RegisterFormKey.shareWithConsultant"
            id="shareWithConsultant"
          />
          <label i18n class="form-check-label" for="shareWithConsultant">
            Share my contact information with my Consultant
          </label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <button type="submit" class="btn btn-primary w-100" [disabled]="disabled" i18n>
          Create Account
        </button>
      </div>
    </div>
  </form>
</div>
