<div class="checkout-slide">
  <div class="row d-none d-md-block my-3">
    <div class="col-md-6">
      <div class="title">{{ title | titlecase }}</div>
    </div>
  </div>
  <form [formGroup]="personalInfoFormGroup" (ngSubmit)="next(2)">
    <div [formGroupName]="BillingCustomer">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <app-text-field-input
              [identifier]="FirstName"
              label="First name"
              i18n-label
              placeholder="Your first name goes here"
              i18n-placeholder
              [formControlName]="FirstName"
              [readonly]="isAuthenticated$ | async"
            >
            </app-text-field-input>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <app-text-field-input
              [identifier]="LastName"
              label="Last name"
              i18n-label
              placeholder="Your last name goes here"
              i18n-placeholder
              [formControlName]="LastName"
              [readonly]="isAuthenticated$ | async"
            >
            </app-text-field-input>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <app-text-field-input
              #emailInput
              [identifier]="Email"
              type="email"
              label="Email address"
              i18n-label
              placeholder="Your email address goes here"
              i18n-placeholder
              [formControlName]="Email"
              [readonly]="isAuthenticated$ | async"
              [isDataPrivate]="true"
            >
            </app-text-field-input>
          </div>
        </div>
        <div class="col-md-6" *ngIf="(isAuthenticated$ | async) === false">
          <div class="form-group">
            <app-text-field-input
              #emailConfirmInput
              [identifier]="EmailConfirm"
              type="email"
              label="Re-enter email address"
              i18n-label
              [groupValidationErrorType]="FormErrorTypes.emailConfirm"
              placeholder="Your email address goes here"
              i18n-placeholder
              [formControlName]="EmailConfirm"
              [isDataPrivate]="true"
            >
            </app-text-field-input>
          </div>
        </div>
        <div
          *ngIf="(emailErrorType$ | async) === PhErrorTypes.Email"
          class="col-12 validation-error-message mb-2"
        >
          <span id="email-error-message" i18n
            >This e-mail cannot be used in Ecommerce because it is already registered on a PH
            consultant!</span
          >
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <app-text-field-input
              [identifier]="PhoneNumber"
              label="Mobile number"
              i18n-label
              placeholder="Your phone number goes here"
              i18n-placeholder
              formControlName="phoneNumber"
              type="tel"
              [isDataPrivate]="true"
            >
            </app-text-field-input>
          </div>
        </div>
      </div>

      <div
        class="row"
        *ngIf="!((isAuthenticated$ | async) === true && (isNewsLetterSubscribe$ | async) === true)"
      >
        <div class="col">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              [formControlName]="Updates"
              id="updates"
            />
            <label i18n class="form-check-label" for="updates">
              Receive future updates from Princess House about new products and promotions
            </label>
          </div>
        </div>
      </div>
    </div>

    <app-shipping-address-form
      i18n-title
      title="Shipping address"
      [countryStates$]="countryStates$"
      [isAuthenticated]="isAuthenticated$ | async"
      [addressVerification]="shippingAddressVerification$ | async"
      id="ShippingAddress"
      (addressChange)="changeAddress()"
      #address
    ></app-shipping-address-form>

    <div class="row">
      <div class="col validation-error-message my-2" *ngIf="addressErrorType$ | async as errorType">
        {{ ErrorMessages[errorType] }}
      </div>
    </div>

    <div class="row mb-2">
      <div class="col">
        <app-address-validation
          [addressValidation$]="shippingAddressVerification$"
          [countryStates$]="countryStates$"
          [finalizeAddress]="saveShippingAddressForm"
          [onDismiss]="onDismissShippingAddress"
        >
        </app-address-validation>
      </div>
    </div>

    <div class="row justify-content-between">
      <div class="col col-md-5">
        <button
          class="btn btn-outline-dark text-uppercase w-100"
          i18n
          routerLink="/shopping-cart"
          [disabled]="disableButton$ | async"
          >Back to Cart
        </button>
      </div>
      <div class="col col-md-5">
        <button
          type="submit"
          class="btn btn-primary text-uppercase w-100"
          i18n
          [disabled]="disableButton$ | async"
          >Continue
        </button>
      </div>
    </div>
  </form>
</div>

<app-my-address-popup
  [userAddresses]="userAddresses$ | async"
  [currentAddress$]="currentAddress$"
  [onDismiss]="dismissSavedAddressesModal"
  (addressSelect)="selectAddress($event)"
></app-my-address-popup>

<ng-container *ngIf="(isAuthenticated$ | async) === false">
  <app-login-modal
    [isAuthenticated$]="isAuthenticated$"
    [email]="loginEmail"
    #loginModal
  ></app-login-modal>
</ng-container>
