import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { resetAddressState } from '../address/address.actions';
import { mergeCarts, newCart } from '../cart/cart.actions';
import { resetCheckoutState } from '../checkout/checkout.actions';
import { fetchUser, fetchUserWithConsultant, resetUserStore } from '../user/user.actions';
import { mergeWishlist, newWishlist } from '../wishlist/wishlist.actions';
import { logInSuccess, logOutSuccess } from './auth.actions';

@Injectable()
export class AuthEffects {
  fetchUserWithConsultant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logInSuccess),
      map(() => fetchUserWithConsultant()),
    ),
  );

  mergeCarts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logInSuccess),
      map(() => mergeCarts()),
    ),
  );

  mergeWishlist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logInSuccess),
      map(() => mergeWishlist()),
    ),
  );

  fetchUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logInSuccess),
      map(() => fetchUser()),
    ),
  );

  resetUserStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logOutSuccess),
      map(() => resetUserStore()),
    ),
  );

  newCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logOutSuccess),
      map(() => newCart()),
    ),
  );

  newWishlist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logOutSuccess),
      map(() => newWishlist()),
    ),
  );

  resetAddressState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logOutSuccess),
      map(() => resetAddressState()),
    ),
  );

  resetCheckoutState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logInSuccess),
      ofType(logOutSuccess),
      map(() => resetCheckoutState()),
    ),
  );

  constructor(private actions$: Actions) {}
}
